<template>
  <v-container
    fluid
    tag="section"
  >
    <validation-observer v-slot="{ invalid }">
      <material-wizard
        :title="title"
        subtitle="Preencha as informações para gerar seu comparativo."
        v-model="tab"
        :available-steps="availableSteps"
        :tabs="tabs"
        class="mx-auto"
        @click:next="next(1)"
        @click:prev="tab--"
        :disabledBtnFinalizar="invalid && tab === tabs.length - 1"
        :loading="loading"
        btnSaveCustomId="btn-save-comparativo"
      >
        <sig-r-wizard-tabs
          ref="sigRParametros"
          :items="comparativo"
          :tucs="tucs"
        />
      </material-wizard>
    </validation-observer>
  </v-container>
</template>

<script>
import SigRComparaService from '@/services/SigRComparaService';
import tourMixins from '@/mixins/tourMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [tourMixins],

  components: {
    SigRWizardTabs: () => import('@/components/sig-r/SigRWizardTabs'),
    MaterialWizard: () => import('@/components/base/MaterialWizard')
  },

  mounted() {
    if (this.$route.params.id) {
      this.getComparativo(this.$route.params.id);
    }
    this.initTourFirstTab();
  },

  data() {
    return {
      typeTourSecondTab: 'completa' /* 'completa' || 'apenas bdgd' */,
      tab: 0,
      tabs: ['Início', 'Tipo do SIG-R', 'TUCs & ODIs'],
      comparativo: {},
      tucs: [],
      loading: false,
      arrTourFirstTab: [
        {
          element: '#titulo-comparativo',
          intro: 'Aqui você poderá inserir um nome para o seu comparativo.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#data-imobilizacao-comparativo',
          intro:
            'Esses campos são opcionais e devem ser preenchido caso você queira um resultado mais específico para seu comparativo, determinando intervalos de data de imobilização.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#group-check-comparativo',
          intro:
            'Aqui você poderá adicionar filtros ao seu comparativo, definindo o que será comparado.',
          scrollTo: 'tooltip',
          position: 'right'
        }
      ],
      arrTourThreeTab: [
        {
          element: '#tucs-comparativo',
          intro:
            'Aqui você poderá selecionar as TUCs que irão compor o resultado de seu comparativo.',
          scrollTo: 'tooltip',
          position: 'left'
        },
        {
          element: '#odis-comparativo',
          intro:
            'Aqui você poderá inserir códigos de ODIs, caso queira um resultado mais específico para seu comparativo.',
          scrollTo: 'tooltip',
          position: 'top'
        },
        {
          element: '#btn-save-comparativo',
          intro:
            'Após seleção dos critérios anteriores clique aqui para salvar e executar o comparativo.',
          scrollTo: 'tooltip',
          position: 'left'
        }
      ],
      desativarTourAoSair: false
    };
  },

  methods: {
    next(valid) {
      if (!valid) return;

      if (this.tab === this.tabs.length - 1) {
        this.salvarComparativo();
      } else {
        this.tab++;
      }
    },

    getComparativo(id) {
      SigRComparaService.getComparativo(id)
        .then((response) => {
          this.comparativo = response.data.data;
          this.tucs = JSON.parse(this.comparativo.tucs);
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error('Erro ao recuperar comparativo.', '', {
            position: 'topRight'
          });
        });
    },

    salvarComparativo() {
      const data = this.$refs.sigRParametros.exportData();
      const id = !this.$route.params.id ? null : this.$route.params.id;
      this.loading = true;

      SigRComparaService.salvarComparativo(data, id)
        .then(() => {
          this.$toast.success('Comparativo salvo com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.push({
            name: routes.state.appRoutes['SIGR_COMPARA_HISTORICO']
          });
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error('Erro ao salvar comparativo.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    },

    initTourFirstTab() {
      this.doneLabel = 'Próximo';
      this.desativarTourAoSair = false;

      let onBeforeExitFunction = {
        func: () => {
          this.tab = 1;
          this.initTourSecondTab();
        }
      };

      this.iniciarTour(this.arrTourFirstTab, [], [], [onBeforeExitFunction]);
    },

    initTourSecondTab() {
      let onBeforeExitFunction = {
        func: () => {
          this.tab = 2;
          this.initTourThreeTab();
        }
      };

      this.iniciarTour(this.arrTourSecondTab, [], [], [onBeforeExitFunction]);
    },

    initTourThreeTab() {
      this.doneLabel = 'Finalizar';
      this.desativarTourAoSair = true;

      let onBeforeExitFunction = {
        func: () => (this.tab = 0)
      };

      this.iniciarTour(this.arrTourThreeTab, [], [], [onBeforeExitFunction]);
    }
  },

  computed: {
    title() {
      return this.$route.params.id
        ? 'Editando Comparativo'
        : 'Adicionar Novo Comparativo';
    },

    availableSteps() {
      let steps = [0];

      steps.push(1);
      steps.push(2);
      steps.push(3);

      return steps;
    },

    arrTourSecondTab() {
      let typeTour = this.$refs.sigRParametros.exportTypeTour();

      if (typeTour == 'apenas bdgd') {
        return [
          {
            element: '#group-tipo-comparativo',
            intro:
              'Aqui você poderá optar entre a verificação completa (físico x contábil) ou apenas uma verificação da BDGD (físico).',
            scrollTo: 'tooltip',
            position: 'bottom'
          },
          {
            element: '#data-base-bdgd-ais-comparativo',
            intro: 'Aqui você poderá inserir a data base e versão da BDGD.',
            scrollTo: 'tooltip',
            position: 'top'
          }
        ];
      } else {
        return [
          {
            element: '#group-tipo-comparativo',
            intro:
              'Aqui você poderá optar entre a verificação completa (físico x contábil) ou apenas uma verificação da BDGD (físico).',
            scrollTo: 'tooltip',
            position: 'bottom'
          },
          {
            element: '#data-base-bdgd-ais-comparativo',
            intro: 'Aqui você poderá inserir a data base e versão da BDGD.',
            scrollTo: 'tooltip',
            position: 'top'
          },
          {
            element: '#tipo-base-contabil',
            intro:
              'Aqui você poderá selecionar qual base contábil será comparada com a BDGD.',
            scrollTo: 'tooltip',
            position: 'top'
          }
        ];
      }
    }
  }
};
</script>

<style scoped>
.divisor-picker {
  margin-bottom: 8px;
}
</style>
