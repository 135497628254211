import APIService from '@/services/APIService.js';

export default {
  getComparativos() {
    return APIService.apiCall().get('/sig-r-compara/get-comparativos');
  },
  deleteComparativo(id) {
    return APIService.apiCall().delete(`/sig-r-compara/${id}`);
  },
  executarComparativo(id) {
    return APIService.apiCall().post(`/sig-r-compara/executar/${id}`);
  },
  duplicarComparativo(id) {
    return APIService.apiCall().post(`/sig-r-compara/duplicar/${id}`);
  },
  salvarComparativo(postData, id) {
    return APIService.apiCall().post(
      `/sig-r-compara/salvar/${id}`,
      JSON.stringify(postData)
    );
  },
  getComparativo(id) {
    return APIService.apiCall().get(`/sig-r-compara/${id}`);
  },
  baixarComparativo(id, config = {}) {
    return APIService.apiCall().get(
      `/sig-r-compara/baixar-arquivo-resultado/${id}`,
      { responseType: 'blob', ...config }
    );
  }
};
